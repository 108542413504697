@import './../../../../scss/theme-bootstrap';

.footer-quick-link-formatter {
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 16px 10px;
  @include breakpoint($landscape-up) {
    flex: 1;
    padding: 35px;
  }
  &__item {
    flex: 1 1 0;
    width: 0;
    @include breakpoint($landscape-up) {
      flex: none;
      width: auto;
    }
  }
}
